<template>
  <div class="w-full">
    <div class="w-full px-2 py-1">
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <div
          class="w-full py-1"
          :class="tipo == 2 ? 'mouse-click' : ''"
          @click="tipo == 2 ? getOSTotalizadores() : ''"
        >
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span>{{ texto }}</span>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span v-if="tipo == 1">
              {{ valores[0] }}
              <b>{{ valores[1] }}</b>
            </span>
            <span class="m-1" v-else-if="tipo == 2 || tipo == 3">
              <b>
                {{ label }}:
                {{ valores[0] }}
                de {{ valores[1] }}
              </b>
            </span>
            <span v-else>
              <b>
                {{ valores[0] }}
                de {{ valores[1] }}
              </b>
            </span>
          </vs-col>
          <div
            aria-valuemin="0"
            aria-valuemax="100"
            class="m-1 el-progress el-progress--line el-progress--text-inside"
          >
            <div class="el-progress-bar">
              <!-- :style="'border-color: ' + color + ' !important'" -->

              <div
                :class="
                  tipo != 1
                    ? 'el-progress-bar__outer border widthBar'
                    : 'el-progress-bar__outer border widthBarBlack'
                "
              >
                <div
                  class="el-progress-bar__inner"
                  :style="
                    'width: ' +
                    (percent > 100 ? 100 : isNaN(percent) ? 0 : percent) +
                    '%; background: ' +
                    color
                  "
                >
                  <div class="el-progress-bar__innerText">
                    <b :style="tipo == 1 ? 'color: #fff' : 'color: #000'">
                      <span style="font-size: 10px" v-if="tipo != 1">
                        {{
                          percent > 100
                            ? "+100"
                            : isNaN(percent)
                            ? "0.00"
                            : percent
                        }}%
                      </span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-col>
    </div>
    <component
      :totalizador="totalizador"
      :filtro="filtro"
      v-bind:is="modalOsTotalizadores"
      :key="reRenderModal"
    ></component>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import osTotalizadores from "./../sideBars/osTotalizadores"
import reclamacoesSideBar from "./../sideBars/reclamacoesSideBar"
import VueApexCharts from "vue-apexcharts"
export default {
  props: {
    texto: null,
    height: { default: 10 },
    tipo: { default: 0 },
    valores: null,
    percent: { default: 0 },
    color: { default: "#00d6b9" },
    label: null,
    filtro: null,
    textColor: null
  },
  data () {
    return {
      reRenderModal: 0,
      modalOsTotalizadores: "",
      totalizador: {}
    }
  },
  methods: {
    async getOSTotalizadores () {
      this.totalizador.valores = this.valores
      this.totalizador.label = this.label
      if (this.label == 'RECLAMACOES') {
        this.modalOsTotalizadores = "reclamacoesSideBar"
      } else {

        this.modalOsTotalizadores = "osTotalizadores"
      }
      this.reRenderModal += 1
    }
  },
  components: {
    ...components,
    VueApexCharts,
    osTotalizadores,
    reclamacoesSideBar
  }
};
</script>
<style scoped lang="scss">
.widthBar {
  height: 16px;
}
.widthBarBlack {
  height: 6px;
}
.mouse-click {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
</style>
