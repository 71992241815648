<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4
        class="px-3 mt-2"
      >Técnicos - Total horas executado: {{totalHoraExec}}h // Total horas nao executado: {{totalHoraNaoExec}}h</h4>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0 mt-2">
          <div id="container-tecnicos" class="vs-con-loading__container">
            <div v-if="active">
              <vs-row>
                <vs-col
                  v-for="(tecnico, index) in tecnicosCard"
                  :key="index"
                  vs-type="flex"
                  vs-w="4"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                >
                  <div class="mx-5 my-5 w-full row-card">
                    <v-card class primary @click="getTecnicoCardSideBar(tecnico)">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <v-avatar class="m-1" size="125" tile>
                          <v-img
                            :src="
                              tecnico.foto
                                ? tecnico.foto
                                : 'https://upload.wikimedia.org/wikipedia/commons/7/72/Default-welcomer.png'
                            "
                          ></v-img>
                        </v-avatar>
                        <div class="w-full">
                          <v-card-title class="headline" v-text="tecnico.nome"></v-card-title>
                          <v-card-subtitle v-text="'Comissão: ' + $currency(tecnico.comissao)"></v-card-subtitle>
                          <v-card-subtitle
                            class="py-0 my-0"
                            v-text="'Total Nao Executado: ' +  (tecnico.totais.total_servicos_exec - tecnico.totais.servicos_exec)"
                          ></v-card-subtitle>
                          <metaChart
                            height="5"
                            texto="Serviços Exec."
                            color
                            :valores="[
                              tecnico.totais.servicos_exec,
                              tecnico.totais.total_servicos_exec,
                            ]"
                            :percent="
                              parseFloat(
                                (tecnico.totais.servicos_exec /
                                  tecnico.totais.total_servicos_exec) *
                                  100
                              ).toFixed(2)
                            "
                          ></metaChart>
                          <metaChart
                            height="5"
                            texto="Horas Exec."
                            color
                            :valores="[
                              tecnico.totais.horas_exec,
                              tecnico.totais.total_horas_exec,
                            ]"
                            :percent="
                              parseFloat(
                                (tecnico.totais.horas_exec /
                                  tecnico.totais.total_horas_exec) *
                                  100
                              ).toFixed(2)
                            "
                          ></metaChart>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </vs-col>
              </vs-row>
            </div>
            <div v-else>
              <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-type="flex" vs-w="4" vs-justify="center" vs-align="center">
                  <p
                    class="mt-10 pt-10"
                    style="font-size: 30px; height: 550px"
                  >Carregando... {{ loading }}%</p>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <component
      :selectedTecnico="selectedTecnico"
      :filtro="filtro"
      v-bind:is="modalCardTecnico"
      :key="reRenderModal"
    ></component>
  </v-card>
</template>
<script>
import components from "@/components/default/exports.js";
import componentsVendas from "./components/charts/exports.js";
import tecnicoCardSideBar from "./components/sideBars/tecnicoCardSideBar";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      tecnicosCard: [],
      totalExec: 0,
      totalNaoExec: 0,
      selectedTecnico: {},
      modalCardTecnico: "",
      reRenderModal: 0,
      loading: 0,
      active: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async prepareDashboard() {
      await this.$vs.loading({
        container: "#container-tecnicos",
        type: "sound",
        scale: 0.6
      });
      this.loading += await 30;
      await this.getTecnicosCard();
      this.loading += await 30;
      this.active = true;
      await this.$vs.loading.close("#container-tecnicos > .con-vs-loading");
    },
    async getTecnicosCard() {
      try {
        this.loading += await 20;
        this.tecnicosCard = await this.$http.post(
          "getTecnicosCard",
          this.filtro
        );
        this.totalExec = this.tecnicosCard.reduce(function(total, card) {
          return total + card.totais.servicos_exec;
        }, 0);
        this.totalNaoExec = this.tecnicosCard.reduce(function(total, card) {
          return (
            total +
            (card.totais.total_servicos_exec - card.totais.servicos_exec)
          );
        }, 0);
        this.totalHoraExec = this.tecnicosCard.reduce(function(total, card) {
          return total + card.totais.horas_exec;
        }, 0);
        this.totalHoraNaoExec = this.tecnicosCard.reduce(function(total, card) {
          return (
            total + (card.totais.total_horas_exec - card.totais.horas_exec)
          );
        }, 0);
        this.loading += await 20;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getTecnicoCardSideBar(tecnico) {
      this.selectedTecnico = tecnico;
      this.modalCardTecnico = "tecnicoCardSideBar";
      this.reRenderModal += 1;
    }
  },
  async mounted() {
    await this.prepareDashboard();
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts,
    tecnicoCardSideBar
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
}
.meta-card {
  transition-duration: 50ms;
  background-color: transparent;
  &:hover {
    transition-duration: 150ms;
    background-color: #0300be33;
    transform: translateY(-2px);
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
