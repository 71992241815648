<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalPieChart"
      :item="{}"
      size="huger"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div id="osTotalizadoresPCO" class="mb-1 vs-con-loading__container">
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Serviço
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase"
                        >{{ totalizador.label }}:</b
                      >
                    </span>
                    <span class="ml-2" style="font-size: 22px; color: #ffca00">
                      <b>
                        {{ totalizador.valores[0] }}
                        de {{ totalizador.valores[1] }}
                      </b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <!-- <VuePerfectScrollbar class="scroll-table-data" :settings="settings"> -->
            <vs-table
              maxHeight="79vh"
              :data="tableData"
              stripe
              pagination
              max-items="10"
            >
              <template slot="thead">
                <vs-th style="width: 7%" id="center">Contrato</vs-th>
                <vs-th style="width: 7%" id="center">Os</vs-th>
                <vs-th style="width: 7%" id="center">Rota</vs-th>
                <vs-th style="width: 7%" id="center">Cliente</vs-th>
                <vs-th style="width: 7%" id="center">Data Inicial</vs-th>
                <vs-th style="width: 7%" id="center">Data Remarcação</vs-th>
                <vs-th style="width: 7%" id="center">Horas</vs-th>
                <vs-th style="width: 7%" id="center">Técnicos</vs-th>
                <vs-th style="width: 7%" id="center">Horário</vs-th>
                <vs-th style="width: 7%" id="center">Tipo</vs-th>
                <vs-th style="width: 7%" id="center">Frase ou Observação</vs-th>
                <vs-th style="width: 7%" id="center">Status Os</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :key="row.contrato"
                  v-for="row in data"
                  :style="
                    row.executado == 1
                      ? 'color: green; background-color: #e8fce8 !important'
                      : row.executado == 2
                      ? 'color: #dd3522;background-color:#f5efef !important;'
                      : ''
                  "
                >
                  <vs-td class="text-center small-font">
                    <a
                      v-if="row.id_contrato > 0"
                      :href="'contrato?id=' + row.id_contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.id_contrato > 0 ? row.id_contrato : "..." }}</b>
                    </a>
                  </vs-td>
                  <vs-td class="text-center small-font">{{ row.id }}</vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.id_rota ? row.id_rota : "--" }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.cliente }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.data_competencia ? row.data_competencia : "--" }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.data_remarcacao ? row.data_remarcacao : "--" }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.horas_exec }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.tecnicos_exec }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.horario }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.tipoServico }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{ row.texto ? row.texto : "--" }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    <b>
                      {{
                        row.executado == 1
                          ? "Executado"
                          : row.executado == 2
                          ? "Não Executado"
                          : row.remarcado
                          ? "Remarcado"
                          : row.confirmado
                          ? "Confirmado"
                          : "Pendente"
                      }}
                    </b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- </VuePerfectScrollbar> -->
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    totalizador: null,
    filtro: null
  },
  data () {
    return {
      tableData: {},
      modalPieChart: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalPieChart = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#osTotalizadoresPCO",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post("getOSTotalizadores", {
          label: this.totalizador.label,
          filtro: this.filtro
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#osTotalizadoresPCO > .con-vs-loading")
      }
    }
  },
  async mounted () {
    this.modalPieChart = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-table-data {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.small-font {
  font-size: 12.5px;
}
</style>
