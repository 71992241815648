
import pieChart from "./pieChart.vue";
import donutChart from "./donutChart.vue";
import infoTooltip from "./infoTooltip.vue";
import metaChart from "./metaChart.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';


export default {
  metaChart,
  pieChart,
  donutChart,
  infoTooltip,
  VuePerfectScrollbar
}
