var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"2000"}},[_c('v-card-actions',{staticClass:"p-0",staticStyle:{"cursor":"pointer","background-color":"#ffca00"},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('h4',{staticClass:"px-3 mt-2"},[_vm._v("Técnicos - Total horas executado: "+_vm._s(_vm.totalHoraExec)+"h // Total horas nao executado: "+_vm._s(_vm.totalHoraNaoExec)+"h")])]),_c('v-expand-transition',[(_vm.show)?_c('div',[_c('v-card-text',{staticClass:"p-0 mt-2"},[_c('div',{staticClass:"vs-con-loading__container",attrs:{"id":"container-tecnicos"}},[(_vm.active)?_c('div',[_c('vs-row',_vm._l((_vm.tecnicosCard),function(tecnico,index){return _c('vs-col',{key:index,attrs:{"vs-type":"flex","vs-w":"4","vs-justify":"flex-start","vs-align":"flex-start"}},[_c('div',{staticClass:"mx-5 my-5 w-full row-card"},[_c('v-card',{attrs:{"primary":""},on:{"click":function($event){return _vm.getTecnicoCardSideBar(tecnico)}}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('v-avatar',{staticClass:"m-1",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":tecnico.foto
                              ? tecnico.foto
                              : 'https://upload.wikimedia.org/wikipedia/commons/7/72/Default-welcomer.png'}})],1),_c('div',{staticClass:"w-full"},[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s(tecnico.nome)}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s('Comissão: ' + _vm.$currency(tecnico.comissao))}}),_c('v-card-subtitle',{staticClass:"py-0 my-0",domProps:{"textContent":_vm._s('Total Nao Executado: ' +  (tecnico.totais.total_servicos_exec - tecnico.totais.servicos_exec))}}),_c('metaChart',{attrs:{"height":"5","texto":"Serviços Exec.","color":"","valores":[
                            tecnico.totais.servicos_exec,
                            tecnico.totais.total_servicos_exec ],"percent":parseFloat(
                              (tecnico.totais.servicos_exec /
                                tecnico.totais.total_servicos_exec) *
                                100
                            ).toFixed(2)}}),_c('metaChart',{attrs:{"height":"5","texto":"Horas Exec.","color":"","valores":[
                            tecnico.totais.horas_exec,
                            tecnico.totais.total_horas_exec ],"percent":parseFloat(
                              (tecnico.totais.horas_exec /
                                tecnico.totais.total_horas_exec) *
                                100
                            ).toFixed(2)}})],1)],1)])],1)])}),1)],1):_c('div',[_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('p',{staticClass:"mt-10 pt-10",staticStyle:{"font-size":"30px","height":"550px"}},[_vm._v("Carregando... "+_vm._s(_vm.loading)+"%")])])],1)],1)])])],1):_vm._e()]),_c(_vm.modalCardTecnico,{key:_vm.reRenderModal,tag:"component",attrs:{"selectedTecnico":_vm.selectedTecnico,"filtro":_vm.filtro}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }