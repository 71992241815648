<template>
  <div class="w-full">
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="2"
      >
        <v-btn class="ma-2" @click="toggleCollapse" outlined color="indigo"
          >Collapsar/Expandir</v-btn
        >
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <el-select
          filterable
          size="small"
          clearable
          multiple
          placeholder="Segmentos"
          v-model="filtro.segmentos"
          class="mx-1"
        >
          <el-option
            v-for="opcao in segmentos"
            :value="opcao.id"
            :label="opcao.nome"
            :key="opcao.id"
          ></el-option>
        </el-select>

        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="filtrar()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      faturamentos: Array,
      segmentos: [],
      filtro: {
        dataDe: "2020-01-01",
        dataAte: "2020-12-30",
        segmentos: [],
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
    };
  },
  methods: {
    async toggleCollapse() {
      await this.$emit("toggleCollapse");
    },
    async filtrar() {
      if (this.filtro.dataDe != undefined && this.filtro.dataAte != undefined) {
        await this.$emit("atualizarFiltro", this.filtro);
      } else {
        this.$vs.notify({
          text: "Selecione um periodo para filtrar",
          color: "danger",
        });
      }
    },
    async mountData() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0];
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0];
      this.filtro.dataDe = firstDay;
      this.filtro.dataAte = lastDay;
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getSegmentos(){
      try{
        this.segmentos = await this.$http.get(`segmento`)
      }catch (err){
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      
    },
  },
  async mounted() {
    await this.mountData();
    await this.filtrar();
    await this.getVendedores();
    await this.getSegmentos()
  },
  components: {
    ...components,
  },
};
</script>
