<template class="home">
  <div id="home">
    <vs-row>
      <div class="px-1 w-full">
        <filtroRelatorio @toggleCollapse="toggleCollapse()" @atualizarFiltro="atualizarFiltro"></filtroRelatorio>
      </div>
    </vs-row>
    <vs-divider color="primary" class="m-0 mb-5"></vs-divider>
    <vs-col vs-w="12">
      <vs-row v-if="active">
        <vs-col vs-w="12">
          <div class="px-1 w-full">
            <servicoAgendado :show="showFirst" :key="reRender" :filtro="filtro"></servicoAgendado>
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-6">
          <div class="px-1 w-full">
            <tecnico :show="showFirst" :key="reRender" :filtro="filtro"></tecnico>
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-6">
          <div class="px-1 w-full">
            <posvenda :show="showFirst" :key="reRender" :filtro="filtro"></posvenda>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" v-else>
        <div class="relatorio">
          <vs-col
            class="mt-20 pt-20"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-icon size="100px" icon="sentiment_dissatisfied"></vs-icon>
          </vs-col>
          <vs-col class="mt-2" vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
            <span class="font-semibold">Filtre um Período</span>
          </vs-col>
        </div>
      </vs-row>
    </vs-col>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import tecnico from "./tecnico";
import posvenda from "./posvenda";
import servicoAgendado from "./servicoAgendado";
import filtroRelatorio from "./filtroRelatorio";
export default {
  data() {
    return {
      reRender: 0,
      showLast: true,
      showFirst: true,
      filtro: { dataDe: null, dataAte: null },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      active: false
    };
  },
  methods: {
    async atualizarFiltro(filtro) {
      if (filtro.dataDe && filtro.dataAte) {
        this.active = true;
      } else {
        this.active = false;
      }
      this.showLast = true;
      this.showFirst = true;
      this.filtro = filtro;
      this.reRender += 1;
    },
    async toggleCollapse() {
      this.showFirst = !this.showFirst;
      this.showLast = !this.showFirst;
    }
  },
  components: {
    ...components,
    tecnico,
    posvenda,
    servicoAgendado,
    filtroRelatorio
  }
};
</script>
<style>
body {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
.relatorio {
  max-height: 80vh !important;
}
</style>
