<template>
  <div>
    <vue-apex-charts
      :key="reRender"
      type="donut"
      :width="width"
      :options="commitDonutChart"
      @dataPointSelection="dataPointSelectionHandler"
      :series="series"
    ></vue-apex-charts>
    <component
      :label="label"
      :dataDeclinio="selectedDataDonut"
      :filtro="filtro"
      v-bind:is="modalDonutChart"
      :key="reRenderModal"
    ></component>
  </div>
</template>
<script>
import components from '@/components/default/exports.js'
import declinioChartSideBar from './../sideBars/declinioChartSideBar'
import VueApexCharts from 'vue-apexcharts'
export default {
  props: {
    propLabels: null,
    series: null,
    indices: null,
    filtro: null,
    tipo: null,
    colors: null,
    label: null,
    width: { default: '440' },
    textColor: null,
  },
  data () {
    return {
      selectedDataDonut: {},
      modalDonutChart: '',
      reRenderModal: 0,
      reRender: 0,
      donutChart: {
        redrawOnParentResize: true,
        colors: this.colors,
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
            title: {
              formatter: function (seriesName) {
                return seriesName.split(': ')[0]
              },
            },
          },
        },
        theme: {
          mode: 'light',
          palette: 'palette1',
          monochrome: {
            enabled: true,
            color: this.colors,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
        legend: {
          position: 'right',
          show: true,
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: function (seriesName, opts) {
            return [
              (seriesName.length > 25
                ? seriesName.split(': ')[0].slice(0, 25) + '...'
                : seriesName.split(': ')[0]) +
              ': ' +
              opts.w.globals.series[opts.seriesIndex],
            ]
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'left',
              },
            },
          },
        ],
      },
    }
  },
  computed: {
    commitDonutChart () {
      let localOptions = Object.assign({}, this.donutChart)
      localOptions.labels = this.propLabels
      this.reRender += 1
      return localOptions
    },
  },
  methods: {
    dataPointSelectionHandler (e, chartContext, config) {
      this.selectedDataDonut.label = this.propLabels[config.dataPointIndex]
      this.selectedDataDonut.indice = this.indices[config.dataPointIndex]
      this.selectedDataDonut.qtd = this.series[config.dataPointIndex]
      this.modalDonutChart = 'declinioChartSideBar'
      this.reRenderModal += 1
    },
  },
  components: {
    ...components,
    VueApexCharts,
    declinioChartSideBar,
  },
}
</script>
<style scoped lang="scss"></style>
