<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalPieChart"
      :item="{}"
      size="huge"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div
          id="servicosAgendadoChartSidebarPCO"
          class="mb-1 vs-con-loading__container"
        >
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Serviço
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase">{{ label }}</b>
                      - {{ dataPie.label.split(": ")[0] }}:&nbsp;
                    </span>
                    <span class="ml-2" style="font-size: 22px; color: #ffca00">
                      <b>{{ dataPie.qtd ? dataPie.qtd : 0 }}</b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <!-- <VuePerfectScrollbar class="scroll-table-data" :settings="settings"> -->
            <vs-table
              maxHeight="79vh"
              pagination
              max-items="10"
              :data="tableData"
              stripe
              id="tableFat"
            >
              <template slot="thead">
                <vs-th style="width: 7%" id="center">Contrato</vs-th>
                <vs-th style="width: 15%" id="center">Cliente</vs-th>
                <vs-th style="width: 7%" id="center">Data Pesquisa</vs-th>
                <vs-th style="width: 7%" id="center">Controle</vs-th>
                <vs-th style="width: 20%" id="center">Observação</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(row, index) in data">
                  <vs-td class="text-center" style="font-size: 11.5px">
                    <a
                      :href="'contrato?id=' + row.id_contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.id_contrato }}</b>
                    </a>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">{{
                    row.cliente
                  }}</vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">{{
                    row.data_pesquisa
                  }}</vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">{{
                    row.controle
                  }}</vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">{{
                    row.obs
                  }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- </VuePerfectScrollbar> -->
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    dataPie: null,
    label: null,
    filtro: null
  },
  data () {
    return {
      tableData: {},
      modalPieChart: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalPieChart = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#servicosAgendadoChartSidebarPCO",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post(
          "getPesquisaSatisfacaoChartSideBar",
          {
            filtro: this.filtro,
            label: this.label,
            indice: this.dataPie.indice
          }
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#servicosAgendadoChartSidebarPCO > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    this.modalPieChart = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-table-data {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
