<template>
  <v-card>
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Pós Venda</h4>
    </v-card-actions>
    <!-- <VuePerfectScrollbar class="prospeccaoVendas" :settings="settings"> -->
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0">
          <div>
            <div id="container-posvenda" class="w-full vs-con-loading__container">
              <div v-if="active">
                <vs-row class="my-2" vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                    <div class="w-full mx-2 row-card">
                      <vs-row vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
                        <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                          <div class="w-full">
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <h5 class="mt-1">ATENDIMENTO COMERCIAL E ADMINISTRATIVO</h5>
                              </vs-col>
                            </vs-row>
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <pieChart
                                :propLabels="pesquisaSatisfacao[0].labels"
                                :series="pesquisaSatisfacao[0].series"
                                :colors="colors.pesquisaSatisfacao[0]"
                                :filtro="filtro"
                                :indices="pesquisaSatisfacao[0].indices"
                                width="300"
                                :percent="23"
                                label="pesquisa satisfação"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                        <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                          <div class="w-full">
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <h5 class="mt-1">ATENDIMENTO OPERACIONAL</h5>
                              </vs-col>
                            </vs-row>
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <pieChart
                                :propLabels="pesquisaSatisfacao[1].labels"
                                :series="pesquisaSatisfacao[1].series"
                                :colors="colors.pesquisaSatisfacao[1]"
                                :filtro="filtro"
                                :indices="pesquisaSatisfacao[1].indices"
                                width="300"
                                :percent="23"
                                label="pesquisa satisfação"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                        <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                          <div class="w-full">
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <h5 class="mt-1">QUALIDADE DO SERVIÇO</h5>
                              </vs-col>
                            </vs-row>
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <pieChart
                                :propLabels="pesquisaSatisfacao[2].labels"
                                :series="pesquisaSatisfacao[2].series"
                                :colors="colors.pesquisaSatisfacao[2]"
                                :filtro="filtro"
                                :indices="pesquisaSatisfacao[2].indices"
                                width="300"
                                :percent="23"
                                label="pesquisa satisfação"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                        <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                          <div class="w-full">
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <h5 class="mt-1">INDICAÇÃO DA EMOPS</h5>
                              </vs-col>
                            </vs-row>
                            <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <pieChart
                                :propLabels="pesquisaSatisfacao[3].labels"
                                :series="pesquisaSatisfacao[3].series"
                                :colors="colors.pesquisaSatisfacao[3]"
                                :filtro="filtro"
                                :indices="pesquisaSatisfacao[3].indices"
                                width="370"
                                :percent="23"
                                label="pesquisa satisfação"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-w="4" vs-justify="center" vs-align="center">
                    <p
                      class="mt-10 pt-10"
                      style="font-size: 30px; height: 320px"
                    >Carregando... {{ loading }}%</p>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <!-- </VuePerfectScrollbar> -->
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      tooltipTextos: {},
      active: false,
      loading: 0,
      colors: {
        pesquisaSatisfacao: ["#20c729", "#00b0ff", "#ff6d00", "#880e4f"]
      },
      pesquisaSatisfacao: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async prepareDashboard() {
      await this.$vs.loading({
        container: "#container-posvenda",
        type: "sound",
        scale: 0.6
      });
      this.loading += await 50;
      await this.getPesquisaSatisfacao();
      this.loading += await 50;
      this.active = true;
      await this.$vs.loading.close("#container-posvenda > .con-vs-loading");
    },
    async getPesquisaSatisfacao() {
      try {
        this.pesquisaSatisfacao = await this.$http.post(
          "getPesquisaSatisfacao",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.prepareDashboard();
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
  color: #1d8ab6, #25a3d4, #2ab9f1, #38c3fa, #71d3fa;
}
</style>
