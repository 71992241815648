<template>
  <v-card>
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00;"
    >
      <h4 class="px-3 mt-2">Serviços Agendados</h4>
    </v-card-actions>
    <!-- <VuePerfectScrollbar class="prospeccaoVendas" :settings="settings"> -->
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0">
          <div>
            <div id="container-servicos-agendado" class="w-full vs-con-loading__container">
              <div v-if="active">
                <vs-row class="my-2" vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                    <div class="w-full mx-2 row-card">
                      <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
                        <div class="w-full">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <h5 class="mt-1">POR RETORNO</h5>
                            </vs-col>
                          </vs-row>
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <pieChart
                              :propLabels="servicosPorRetorno.pie.labels"
                              :series="servicosPorRetorno.pie.series"
                              :indices="servicosPorRetorno.pie.indices"
                              :filtro="filtro"
                              :colors="colors.porRetorno"
                              :percent="23"
                              label="por retorno"
                            ></pieChart>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
                        <div class="w-full">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col
                              vs-w="11"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <h5>POR TIPO DE SERVIÇO</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="Serviços Agendados"
                                :texto="tooltipTextos.servicosAgendados"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <pieChart
                              :propLabels="servicosPorTipo.pie.labels"
                              :series="servicosPorTipo.pie.series"
                              :indices="servicosPorTipo.pie.indices"
                              :filtro="filtro"
                              :percent="23"
                              :colors="colors.porTipo"
                              label="por tipo"
                            ></pieChart>
                          </vs-row>
                        </div>
                      </vs-col>

                      <vs-col
                        vs-w="12"
                        class="my-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      ></vs-col>
                      <vs-col vs-w="2.4" vs-type="flex" vs-justify="center" vs-align="center">
                        <h3 class="mb-6 mt-4">
                          TOTAL:
                          <b>{{ servicosPorRetorno.totalizador.total }}</b>
                        </h3>
                      </vs-col>
                      <vs-col vs-w="2.4" vs-type="flex" vs-justify="center" vs-align="center">
                        <div class="w-full">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="2"
                                :filtro="filtro"
                                :valores="[
                                  servicosPorRetorno.totalizador.confirmados,
                                  servicosPorRetorno.totalizador.total,
                                ]"
                                :percent="
                                  (
                                    parseFloat(
                                      servicosPorRetorno.totalizador
                                        .confirmados /
                                        servicosPorRetorno.totalizador.total,
                                    ) * 100
                                  ).toFixed(2)
                                "
                                color
                                height="5"
                                label="CONFIRMADOS"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col vs-w="2.4" vs-type="flex" vs-justify="center" vs-align="center">
                        <metaChart
                          tipo="2"
                          :filtro="filtro"
                          :valores="[
                            servicosPorRetorno.totalizador.remarcados,
                            servicosPorRetorno.totalizador.total,
                          ]"
                          :percent="
                            (
                              parseFloat(
                                servicosPorRetorno.totalizador.remarcados /
                                  servicosPorRetorno.totalizador.total,
                              ) * 100
                            ).toFixed(2)
                          "
                          height="5"
                          color
                          label="REMARCADOS"
                        ></metaChart>
                      </vs-col>

                      <vs-col vs-w="2.4" vs-type="flex" vs-justify="center" vs-align="center">
                        <metaChart
                          tipo="2"
                          :filtro="filtro"
                          :valores="[
                            servicosPorRetorno.totalizador.foraRota,
                            servicosPorRetorno.totalizador.total,
                          ]"
                          :percent="
                            (
                              parseFloat(
                                servicosPorRetorno.totalizador.foraRota /
                                  servicosPorRetorno.totalizador.total,
                              ) * 100
                            ).toFixed(2)
                          "
                          height="5"
                          color
                          label="FORA DA ROTA"
                        ></metaChart>
                      </vs-col>

                      <vs-col vs-w="2.4" vs-type="flex" vs-justify="center" vs-align="center">
                        <metaChart
                          tipo="2"
                          :filtro="filtro"
                          :valores="[
                            servicosPorRetorno.totalizador.reclamacoes,
                            servicosPorRetorno.totalizador.total,
                          ]"
                          :percent="
                            (
                              parseFloat(
                                servicosPorRetorno.totalizador.reclamacoes /
                                  servicosPorRetorno.totalizador.total,
                              ) * 100
                            ).toFixed(2)
                          "
                          color
                          height="5"
                          label="RECLAMACOES"
                        ></metaChart>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-row class="my-2" vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-2 w-full row-card">
                      <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                        <div class="w-full py-1">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >&nbsp;</vs-col>
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">DECLINIO</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="DECLINIO"
                                :position="'left'"
                                :texto="tooltipTextos.declino"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <donutChart
                                :propLabels="naoRetornadosDeclinio.donut.labels"
                                :series="naoRetornadosDeclinio.donut.series"
                                :colors="colors.declinio"
                                :filtro="filtro"
                                :indices="naoRetornadosDeclinio.donut.indices"
                                :tipo="2"
                                :percent="23"
                                label="declinio"
                              ></donutChart>
                            </vs-col>
                          </vs-row>
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="1"
                                :valores="[
                                  'Total Declinios: ',
                                  naoRetornadosDeclinio.total.quantidade,
                                ]"
                                :percent="100"
                                color="black"
                                height="5"
                                label="Ativas"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-2 w-full row-card">
                      <vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                        <div class="w-full py-1">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >&nbsp;</vs-col>
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">METAS A ATINGIR</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="DECLINIO"
                                :position="'left'"
                                :texto="tooltipTextos.metasAtingir"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                            <vs-col vs-type="flex" vs-w="6" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="1"
                                :valores="[
                                'SATISFAÇÃO: ',
                                 metasAtingir.total.satisfacao + '%',
                                  metasAtingir.metas.satisfacao.toFixed(2) + '%',
                                ]"
                                :percent="parseFloat((metasAtingir.total.satisfacao)).toFixed(2)"
                                height="5"
                                :color="metasAtingir.total.satisfacao > metasAtingir.metas.satisfacao ? '#00d6b9' : '#ed6c5f'"
                                label
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-type="flex" vs-w="6" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="1"
                                :valores="[
                                'CONFIRMAÇÃO: ',
                                 metasAtingir.total.confirmacao + '%',
                                  metasAtingir.metas.confirmacao.toFixed(2)  + '%',
                                ]"
                                :percent="parseFloat(metasAtingir.total.confirmacao).toFixed(2)"
                                height="5"
                                :color="metasAtingir.total.confirmacao > metasAtingir.metas.confirmacao ? '#00d6b9' : '#ed6c5f'"
                                label
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            class="mt-5"
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-type="flex" vs-w="6" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="1"
                                :valores="[
                                'RETORNOS: ',
                                 metasAtingir.total.retorno + '%',
                                  metasAtingir.metas.retorno.toFixed(2)  + '%',
                                ]"
                                :percent="parseFloat(metasAtingir.total.retorno).toFixed(2)"
                                height="5"
                                :color="metasAtingir.total.retorno > metasAtingir.metas.retorno ? '#00d6b9' : '#ed6c5f'"
                                label
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-type="flex" vs-w="6" vs-justify="center" vs-align="center">
                              <metaChart
                                tipo="1"
                                :valores="[
                                'NÃO EXECUTADOS: ',
                                 metasAtingir.total.nao_exec + '%',
                                  metasAtingir.metas.nao_exec.toFixed(2)  + '%',
                                ]"
                                :percent="parseFloat((metasAtingir.total.nao_exec)).toFixed(2)"
                                height="5"
                                :color="metasAtingir.total.nao_exec > metasAtingir.metas.nao_exec ? '#ed6c5f' : '#00d6b9'"
                                label
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-w="4" vs-justify="center" vs-align="center">
                    <p
                      class="mt-10 pt-10"
                      style="font-size: 30px; height: 320px;"
                    >Carregando... {{ loading }}%</p>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <!-- </VuePerfectScrollbar> -->
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      tooltipTextos: {
        servicosAgendados: "Servicos agendados",
        declino: "Declinio",
        metasAtingir: "Metas a Atingir"
      },
      active: false,
      loading: 0,
      servicosPorRetorno: {},
      servicosPorTipo: {},
      colors: {
        porRetorno: "#20c729",
        porTipo: "#1d8ab6",
        declinio: "#e22424"
      },
      naoRetornadosDeclinio: {},
      metasAtingir: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async prepareDashboard() {
      await this.$vs.loading({
        container: "#container-servicos-agendado",
        type: "sound",
        scale: 0.6
      });
      this.loading += await 10;
      await this.getServicosPorRetorno();
      await this.getServicosPorTipo();
      await this.getServicosDeclinio();
      await this.getMetasAtingir();
      this.loading += await 10;
      this.active = true;
      await this.$vs.loading.close(
        "#container-servicos-agendado > .con-vs-loading"
      );
    },
    async getServicosDeclinio() {
      try {
        this.naoRetornadosDeclinio = await this.$http.post(
          "getServicosDeclinio",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getServicosPorRetorno() {
      try {
        this.servicosPorRetorno = await this.$http.post(
          "getServicosPorRetorno",
          this.filtro
        );
        this.loading += await 30;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getServicosPorTipo() {
      try {
        this.servicosPorTipo = await this.$http.post(
          "getServicosPorTipo",
          this.filtro
        );
        this.loading += await 30;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getMetasAtingir() {
      try {
        this.metasAtingir = await this.$http.post("getMetasAtingir");
        this.metasAtingir.total = {
          satisfacao: (
            100 -
            parseFloat(
              this.servicosPorRetorno.totalizador.reclamacoes /
                this.servicosPorRetorno.totalizador.total
            ) *
              100
          ).toFixed(2),
          confirmacao: (
            parseFloat(
              this.servicosPorRetorno.totalizador.confirmados /
                this.servicosPorRetorno.totalizador.total
            ) * 100
          ).toFixed(2),
          retorno: (
            parseFloat(
              (this.servicosPorRetorno.pie.series[0] +
                this.servicosPorRetorno.pie.series[1]) /
                this.servicosPorRetorno.totalizador.total
            ) * 100
          ).toFixed(2),
          nao_exec: (
            parseFloat(
              this.servicosPorRetorno.pie.series[1] /
                this.servicosPorRetorno.totalizador.total
            ) * 100
          ).toFixed(2)
        };
        this.loading += await 20;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.prepareDashboard();
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
  color: #1d8ab6, #25a3d4, #2ab9f1, #38c3fa, #71d3fa;
}
</style>
